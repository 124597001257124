import { createRoot } from "react-dom/client";

import Home from "./pages/Home";
import "./index.css";
import ProductsContextProvider from "./ProductsApiContext";
import PaymentContextProvider from "./PaymentContext";

const container = document.getElementById("wljContainer")!;
const root = createRoot(container);

root.render(
  <ProductsContextProvider>
    <PaymentContextProvider>
      <Home />
    </PaymentContextProvider>
  </ProductsContextProvider>
);
