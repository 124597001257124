import lodash from "lodash";
import { DeploymentHost, IStringIndex, TEnvParams, TJourneyMode } from "./types";
import { getEnvironment } from "./config";

export const getEnvParams = (): TEnvParams => {
  const container = document.getElementById("wljContainer")!;
  const urlParams = new URL(window.location.href);
  const embeddedParams = {
    uuid: container.getAttribute("uuid"),
    productId: container.getAttribute("productId"),
    hostname: container.getAttribute("hostname"),
  };
  const isEmbedded = Boolean(
    embeddedParams.uuid && embeddedParams.productId && embeddedParams.hostname
  );

  const mode =
    isEmbedded ||
    !["localhost", "development"].includes(getEnvironment(urlParams.hostname as DeploymentHost))
      ? null
      : urlParams.searchParams.get("mode");

  const hostname = (
    isEmbedded ? embeddedParams.hostname ?? urlParams.hostname : urlParams.hostname
  ) as DeploymentHost;

  return {
    hostname,
    environment: getEnvironment(hostname),
    isEmbedded,
    uuid: isEmbedded ? embeddedParams.uuid : urlParams.searchParams.get("uuid"),
    productId: isEmbedded ? embeddedParams.productId : urlParams.searchParams.get("productId"),
    quoteId: isEmbedded ? null : urlParams.searchParams.get("quoteId"),
    userSessionToken: isEmbedded ? null : urlParams.searchParams.get("user_session_token"),
    mode: (mode ? mode.toUpperCase() : null) as TJourneyMode,
  } as TEnvParams;
};

export function getJSONProperty(obj: any, notation: string, debug = false) {
  if (debug) {
    console.log("getJSONProperty", JSON.stringify(obj, null, 2), notation);
  }
  return notation.split(".").reduce((a, b) => a[b], obj);
}

export const substituteForHeadings = (
  text: string,
  values: IStringIndex<string>,
  debug = false
): string => {
  return text.replace(/\$\{[^}]+}/g, (match) => {
    const notation = match.substring(2, match.length - 1);
    const s = getJSONProperty(values, notation, debug);
    if (typeof s === "string") {
      return toTitleCase(s);
    }
    return s || match;
  });
};

export const substituteVariables = (text: string, values: IStringIndex<string>): any => {
  const matches = text.matchAll(/\${[^}]+}/g);
  for (let m of matches) {
    const match = m[0];
    const notation = match.substring(2, match.length - 1);
    const t = text.substring(0, m.index);
    const v = lodash.get(values, notation); // ?? match;
    return t.length ? t + v : v;
  }
  return text;
};

export const populateTemplate = (template: any, values: IStringIndex<any>) => {
  if (!template) {
    return;
  }
  const templateCopy = JSON.parse(JSON.stringify(template)); // Make a deep copy
  const validatedValues = values;

  // Helper to go over all fields in an object structure and replace values
  const replaceValues = (obj: any) => {
    //console.log(replaceValues, obj);
    for (let [key, value] of Object.entries(obj)) {
      if (typeof value === "object") {
        if (value === null) {
          obj[key] = null;
        } else {
          replaceValues(value);
        }
      } else {
        const replaced =
          typeof value === "string" ? substituteVariables(value, validatedValues) : value;
        //console.log(`Setting ${key} to ${replaced} (${typeof replaced})`);
        obj[key] = replaced;
      }
    }
  };

  replaceValues(templateCopy);
  //console.log(JSON.stringify(templateCopy, null, 2));
  return templateCopy;
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getTitleFromKey(docKey: string) {
  const docParts = docKey.split("/");
  return dashesToSpaces(docParts[docParts.length - 1]).replace(".pdf", "");
}

export function dashesToSpaces(str: string) {
  return str.replaceAll("-", " ");
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
